//layout
body{

}

// ラッパー
#wrapper{
	width: 100%;background: $RED;overflow: hidden;min-width: 1000px;
}

// ヘッダー
header{
	width: 100%;
	height: 43px;background: $RED;
	position: fixed;top:0;left:0;z-index: 1000;
}

.logo{
	position: fixed;top:8px;left:36px;transition:all 0.3s ease;
	img{
		position: absolute;left:0;top:0;
	}
	.logo-color,.logo-white-color{opacity: 0;}
}

// グローバルナビ
.navi-wrap{
	
}

#menu-btn{
	position: absolute;right:33px;bottom:5px;
	width: 8px;height: 16px;transition:all 0.3s ease;
	.menu-line{
		display: block;width: 8px;height: 2px;
		background: #fff;
		position: absolute;left:0;
		&:nth-child(1){top:0;}
		&:nth-child(2){top:7px;}
		&:nth-child(3){top:14px}
	}
}

#gnavi{
	position: absolute;
	right:70px;bottom:11px;transition:all 0.3s ease;
	path{
		fill:#fff;
	}
	
	.gnavi{
		&-list{
			display: flex;justify-content: space-between;width: 864px;
		}
		&-item{
			line-height: 1;position: relative;
			&:before{
				content:"";height: 1px;width: 100%;background: #fff;
				position: absolute;bottom:-12px;left:0;opacity: 0;
				transition:all 0.2s ease;
			}
			&.active{
				&:before{opacity: 1;bottom:-3px;}
			}
		}
		&-top{width: 3.71%;}
		&-concept{width: 8.66%;}
		&-lesson{width: 11.42%;}
		&-lecturer{width: 8.63%;}
		&-course{width: 9.99%;}
		&-schedule{width: 12.10%;}
		&-gallery{width: 7.11%;}
		&-access{width: 16.01%;}
	}
	a{
		display: block;transition:opacity 0.3s linear;
		&:hover{
			opacity: 0.7;
		}
	}
}

article{
	position: relative;z-index: 2;
}


// サイドバー 
.side{

}

// フッター
footer{

}
.copymark{
	font-family: Verdana,"Droid Sans";
}