//tablet
.tbdisp{display: block;}
.tbhide{display: none;}



#wrapper{
	min-width: 0;
}

#menu-btn{
	position: absolute;right:16px;bottom:2px;z-index: 999;
	width: 44px;height: 44px;transition:all 0.3s ease;
	cursor: pointer;
	.menu-line{
		display: block;width: 24px;height: 2px;left:10px;
		&:nth-child(1){top:16px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
		&:nth-child(2){top:23px;transition: transform 0.1s $ease2 0s;}
		&:nth-child(3){top:30px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
	}
}
.navi-wrap{
	display: block;visibility: hidden;opacity: 0;transition: all 0.5s $ease5;
	width: 100%;height: 100%;padding: 80px 0;background: rgba($RED,0.8);backdrop-filter:blur(3px);
	overflow: auto;	-webkit-overflow-scrolling:touch;
	position: fixed;z-index: 998;top:0;left:0;
}
#gnavi{
	position: relative;z-index: 1;	padding: 0 8%;width: 100%;
}
#menu.open{
	#menu-btn{
		.menu-line{
			background: #fff !important;
			&:nth-child(1){top:23px;transform: rotate(-135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
			&:nth-child(2){transform: scaleX(0);}
			&:nth-child(3){top:23px;transform: rotate(135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
		}
	}
	.navi-wrap{visibility: visible;opacity: 1;}
}


#gnavi{
	width: 100%;
	left:0;top:50%;transform: translateY(-50%);
	path{
		fill:#fff !important;
	}
	.gnavi{
		&-list{
			width: 80%;margin: 0 auto;flex-wrap: wrap;
		}
		&-item{
			width: 45%;text-align: center;margin: 20px 0;
			&.active{
				&:before{opacity: 1;bottom:0;}
			}
		}
		
		&-top svg{width: calc(3.71% * 5);}
		&-concept svg{width: calc(8.66% * 5);}
		&-lesson svg{width: calc(11.42% * 5);}
		&-lecturer svg{width: calc(8.63% * 5);}
		&-course svg{width: calc(9.99% * 5);}
		&-schedule svg{width: calc(12.10% * 5);}
		&-gallery svg{width: calc(7.11% * 5);}
		&-access svg{width: calc(16.01% * 5);}
		
	}
	a{
		padding: 10px 0;
	}
}

.top-logo{
	&.is-move{
		left:50vw;top:20vh;
	}
	&.lesson{
		transition:none;
	}
	&.is-out{
		top:calc(300vh + 20vh);transition:none;
	}
}


.id-concept{
	&:before{
		border-width: 32vh 43vw 42vh;
	}
	&.cover-fixed{
		&:before{
			border-width: 26vh 43vw 48vh;
		}
	}
	.concept-box{
		width: 80vw;
		top:auto;bottom:120px;left:50vw;transform: translateX(-50%);
	}
}

.id-lesson{
	.lesson-box{
		width: 80vw;max-width: none;
		top:auto;bottom:60px;left:50vw;transform: translateX(-50%);
	}
}
.id-lecturer{
	.lecturer-box{
		width: 80vw;max-width: none;
		top:auto;bottom:60px;left:50vw;transform: translateX(-50%);
	}
	.lecturer-text{
		max-width: none;
	}
}

.id-course{
	&:before{
		height: calc(((100vh - 43px) / 5) * 4);
	}
	
	.course-box{
		display: block;
		width: 80vw;bottom:calc((((100vh - 43px) / 5) * 4) / 2);
	}
	.course-left,.course-right{
		width: 100%;
	}
	
}

.id-schedule{
	&:before{
		height: calc(((100vh - 43px) / 5) * 4 + 44px);
	}
	.schedule-box{
		display: block;
		width: 80vw;top:calc((((100vh - 43px) / 5) * 4) / 2);
	}
	.schedule-left,.schedule-right{
		width: 100%;
	}
	.schedule-right{
		margin-top: 40px;
	}
}

.id-gallery1{
	.gallery-box1{
		width: 50vw;height: calc((100vh - 43px) / 5 * 3);
		left:25vw;top: calc(((100vh - 43px) / 5) + 43px);
	}
	.gallery-logo{
		width: 251px;height: auto;
		img{@include max(w);top:-27px;}
		&:before{
			width: 309px;height: 111px;
		}
	}
}
.id-gallery2{
	&:before{
		border: calc(25vw + 1px) solid rgba($RED,0.85);
		border-top-width: calc((100vh - 43px) / 5 + 44px);border-bottom-width: calc((100vh - 43px) / 5 + 1px);
	}
}

.id-access{
	height: auto;min-height: 100vh;
	.access-box{
		width: 80vw;
	}
	.access-inner{
		display: block;
	}
	.access-left,.access-right{
		width: 100%;
	}
	.access-right{
		margin-top: 20px;
	}
	.access-tb{
		tbody th{text-align: left;}
		tbody td{
			padding-left: 10px;
		}
	}
	.access-map{
		text-align: left;padding-left: 70px;width: 363px;
	}
	.access-address{
		padding-left: 70px;
	}
	.access-cake-line{
		display: none;
	}
}

.bg4{
	grid-template-rows:1fr 1fr 1fr 1fr 1fr;
	grid-template-columns:1fr 1fr 1fr 1fr;
}
.bg5{
	grid-template-rows:1fr 1fr 1fr 1fr 1fr;
	grid-template-columns:1fr 1fr 1fr 1fr;
}

.cake-line{
	svg{
		left: auto;right:-30vw;transform: translateX(0);
	}
}

