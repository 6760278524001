@charset "utf-8";
@import "settings/vars";
@import "settings/mixins";
@import "base/reset";
@import "base/base";
@import "contents/module";
@import "contents/layout";
@import "contents/page";
@import "contents/animation";


//印刷
@media print {
	@import "print";
}

//small pc
@import "responsive/pc";

//tablet
@media only screen and (max-width: $BREAK_TAB){
	@import "responsive/tablet"; 
}

//mobile
@media only screen and (max-width: $BREAK_SP){
	@import "responsive/sp"; 
}