//animation

@keyframes top-logo-glow{
	0%{opacity: 0;}
	50%{opacity: 1;}
	100%{opacity: 0;}
}

@keyframes top-line-glow{
	0%{filter: drop-shadow(0 0 0 #EF8661);}
	50%{filter: drop-shadow(0 0 4px #EF8661);}
	100%{filter: drop-shadow(0 0 0 #EF8661);}
}


@keyframes message-jp1{
	0%{opacity: 0;}
	100%{opacity: 1;}
}