//small pc
@media only screen and (max-width: 1280px){
	.id-lecturer{
		.lecturer-book-list{
			img{height: 85px;width: auto;}
		}
	}

}
@media only screen and (max-width: 1140px){
	.logo{
		left:25px;
	}
	#menu-btn{
		right:25px;
	}
	#gnavi{
		right:55px;
		.gnavi-list{
			width: 750px;
		}
		.gnavi-item{
			transform: scale(1.1);
		}
	}
}