//base
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&family=Noto+Serif+JP:wght@700&display=swap');

*,:before,:after{box-sizing:border-box; word-break: break-all;}

html{
	font-size: 62.5%;
}

img{
	vertical-align: middle;
}
ol{
	list-style-position: inside;
}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:$FONT_SIZE;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;letter-spacing: 0.1em;
	font-feature-settings: "palt";	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
}


::selection {
	background: $GRAY;
	color: #fff;
}

.mincho{
	font-family: YakuHanMP_Noto,'Noto Serif JP', serif;font-weight: 700;letter-spacing: 0.07em;
}