.spdisp{display: block;}
.sphide{display: none;}

.logo{
	left:20px;
	img{width: 115px;height: 25px;}
}

#gnavi{
	.gnavi{
		&-list{
			width: 90%;margin: 0 auto;flex-wrap: wrap;
		}
		&-top svg{width: calc(3.71% * 7);}
		&-concept svg{width: calc(8.66% * 7);}
		&-lesson svg{width: calc(11.42% * 7);}
		&-lecturer svg{width: calc(8.63% * 7);}
		&-course svg{width: calc(9.99% * 7);}
		&-schedule svg{width: calc(12.10% * 7);}
		&-gallery svg{width: calc(7.11% * 7);}
		&-access svg{width: calc(16.01% * 7);}
	}
	a{
		padding: 10px 0;
	}
}

.top-logo{
	width: 264px;height: auto;
	img{@include max(w);}
	&:before,&:after{
		width: 322px;height: 115px;top:-30px;
	}
	&.lesson{
		position: fixed;top:-30px;transition:top 0.3s ease;
	}
	&.is-out{
		
	}
	.concept-sitename{
		margin-top:15px;
	}
}
.id-top{
	
}
.id-concept{
	min-height: 100vh;height: auto;padding-top: 43px;
	&:before{
		width:100%;height: calc(50vh + 300px);border: 10vh solid rgba(#fff,0.88);border-width: 50vh 41vw 38px !important;
	}
	&:after{
		content:"";
		width:100%;height:calc(100% - 50vh - 300px + 44px);background: rgba(#fff,0.88);
		position: absolute;left:0;top:calc(50vh + 300px);z-index: 2;
	}
	&.cover-fixed{
		&:before{position: absolute;top:0;}
	}
	.concept-box{
		width: 84vw;margin-top: calc(50vh + 300px);
		position: relative;bottom:0;padding-bottom: 80px;
	}
	.concept-tit{
		font-size: 2.1rem;
		.tit-l{font-size: 2.6rem;}
		.tit-en{
			transform: scale(0.8);transform-origin: left bottom;
		}
	}
	.concept-text{
		font-size: 1.2rem;
	}
}


.id-lesson{
	height: auto;min-height: 100vh;
	padding-top: 43px;
	&:before{
		content:"";
		width:100%;height: calc(100vh + 300px);border: 10vh solid rgba(#fff,0.88);border-width: 100vh 41vw 38px;
		position: absolute;left:0;top:43px;z-index: 2;
	}
	&:after{
		content:"";
		width:100%;height:calc(100% - 100vh - 300px - 43px);background: rgba(#fff,0.88);
		position: absolute;left:0;top:calc(100vh + 300px + 43px);z-index: 2;
	}
	.lesson-box{
		width: 84vw;position: relative;bottom:0;margin-top: calc(100vh + 300px + 43px);padding-bottom: 80px;
	}
	.lesson-tit{
		font-size: 2.1rem;
		.tit-l{font-size: 2.6rem;}
		.tit-en{
			transform: scale(0.8);transform-origin: left bottom;
		}
	}
	.lesson-subtit{
		font-size: 1.6rem;
	}
	.lesson-feature-tit{
		font-size: 1.3rem;margin-top: 10px;
	}
	.lesson-feature-text{
		font-size: 1.2rem;padding-left: 14px;
	}
}

.id-lecturer{
	height: auto;padding-top: 43px;
	&:before{
		content:"";
		width:100%;height: calc(100vh + 300px);border: 10vh solid rgba(#fff,0.88);border-width: 100vh 41vw 38px;
		position: absolute;left:0;top:0;z-index: 2;
	}
	&:after{
		content:"";
		width:100%;height:calc(100% - 100vh - 300px + 43px);background: rgba(#fff,0.88);
		position: absolute;left:0;top:calc(100vh + 300px);z-index: 2;
	}
	.lecturer-box{
		width: 84vw;position: relative;bottom:0;margin-top: calc(100vh + 300px);padding-bottom: 80px;
	}
	.lecturer-tit{
		font-size: 2.1rem;
		.tit-en{
			transform: scale(0.8);transform-origin: left bottom;
		}
	}
	.lecturer-text{
		font-size: 1.2rem;
	}
	.lecturer-book-list{
		margin-top: 20px;margin-left: 0;
		li{
			width: 33%;text-align: center;margin-left: 0;margin-top: 20px;
		}
		img{
			height: 100px;
		}
	}
}
.id-course{
	z-index: 6;height: auto;padding-top: calc((100vh - 43px) / 5 + 43px);padding-bottom: calc((100vh - 43px) / 5);
	&:before{display: none;}
	.course-box{
		position: relative; transform: none;width: 100vw;left:auto;bottom:auto;padding: 45px 8vw;
		background: rgba(255,255,255,0.88);
	}
	.course-tit{
		position: relative;
		font-size: 2.1rem;
		.tit-en{
			transform: scale(0.8);transform-origin: left bottom;
		}
	}
	.course-left{
		
	}
	.course-right{
		padding-top: 0;
	}
	.course-text,.course-notice{
		font-size: 1.2rem;
	}
}

.id-schedule{
	z-index: 7;height: auto;padding-bottom: calc((100vh - 43px) / 5 - 43px);
	&:before{display: none;}
	.schedule-box{
		position: relative; transform: none;width: 100vw;left:auto;top:auto;padding: 45px 8vw;
		background: rgba(255,255,255,0.88);
	}
	.schedule-tit{
		font-size: 1.8rem;
	}
	.schedule-tb,.schedule-notice,.schedule-text,.cancel-text{
		font-size: 1.2rem;
	}
	.schedule-tb{
		
	}
}

.id-gallery1{
	z-index: 8;
	.gallery-box1{
		width: 100vw;left:0;
	}
}
.id-gallery2{
	z-index: 9;
	&:before{
		border-left: none;border-right: none;
	}
}

.id-gallery2{
	z-index: 9;
}
.id-access{
	z-index: 10;background: $RED;height: auto;min-height:0;
	.access-box{
		width: 100%;top:0;height: auto;
	}
	.access-inner{
		position: static;transform: none;padding: 103px 8vw 100px;
		background: rgba(#fff,0.55);
	}
	.access-link-text{
		font-size: 1rem;
	}
	.access-text,.access-notice{
		font-size: 1.2rem;
	}
	.access-map{
		padding-left: 0;width: 100%;
	}
	.access-address{
		padding-left: 0;font-size: 1.2rem;
	}
	.access-en{
		bottom:5vh;
	}
}



.bg1{
	&.lesson{
		position: fixed;top:43px
	}
}
.bg2{
	&.sp-lesson{
		position: fixed;top:43px
	}
}
.bg3{
	&.sp-lecturer{
		position: fixed;top:43px
	}
}
.bg4{
	&.schedule{
		position: fixed;top:43px
	}
}
.bg6{
	&.sp-access{
		position: fixed;top:43px
	}
}