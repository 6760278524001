//page
$glow-time:2.5s;
$glow-delay:0.8s;
.id-top{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: calc(100% - 43px);background: rgba(#FFF,0.88);
		position: absolute;left:0;top:43px;z-index: 2;
	}
}
.top-logo{
	position: fixed;top:50vh;left:50vw;transform: translate(-50%,-50%);
	transition: all 1.5s $ease9; z-index: 101;
	width: 378px;height: 134px;
	&:before,&:after{
		content:"";width: 461px;height: 164px;
		background: url(../images/logo_glow.png) center center no-repeat;background-size:cover;
		position: absolute;left:50%;top:-42px;transform: translateX(-50%);opacity: 0;z-index: 1;
		
	}
	.top-logo-mark{
		position: relative;z-index: 2;display: block;
	}
	.concept-sitename{
		display: inline-block;padding-left: 55px;margin-top: 33px;
		opacity: 0;position: relative;left:50px;
		transition:all 0.5s ease 0.2s;
	}
	&.is-move{
		left:20%;
		.concept-sitename{
			opacity: 1;left:0;
		}
	}
	&.is-glow{
		&:after{
			animation: top-logo-glow $glow-time ease-in-out 0s 1 normal both;
		}
	}
	&.is-out{
		position: absolute;top:calc(300vh + 50vh);transition: none;
	}
	&.lesson{
		transition: none;
		.concept-sitename{opacity: 0;left: -50px;}
	}
}
.loading-cover{
	width: 100vw;height: 100vh;background: #FFF;
	position: fixed;top:0;left:0;z-index: 100;
	transition:all 0.5s $ease5 0.4s;
}

body.is-loaded{
	.top-logo{
		&:before{
			animation: top-logo-glow $glow-time ease-in-out $glow-delay 1 normal both;
		}
	}
	.cake-line{
		animation: top-line-glow $glow-time ease-in-out $glow-delay 1 normal both;
	}
	.loading-cover{
		opacity: 0;visibility: hidden;
	}
}

.id-concept{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: 100%;
		position: absolute;left:0;top:0;z-index: 2;
		border: 10vh solid rgba(#fff,0.88);border-width: 14.5vh 44.9vw;
		transition:border 0.2s $ease5;
	}
	&.cover-fixed:before{
		position: fixed;
	}
	&.cover-release:before{
		position: absolute;top:200vh;
	}
	.concept-box{
		width: 336px;
		position: absolute;z-index: 3;top:50vh;left:60vw;transform: translateY(-50%);
	}
	.concept-tit{
		font-size: 2.4rem;line-height: 1.2;
		.tit-l{
			font-size: 3rem;
		}
		.tit-jp{
			position: relative;z-index: 2;
		}
		.tit-en{
			position: absolute;z-index: 1;left:88px;top:-10px;
		}
	}
	.concept-text{
		font-size: 1.5rem;line-height: 1.4;margin-top: 20px;	text-align: justify;
		&:first-of-type{
			margin-top: 15px;
		}
	}
	
}

.id-lesson{
	height: 100vh;position: relative;
	.lesson-box{
		max-width: 455px;width: 35vw;
		position: absolute;z-index: 3;top:50vh;left:60vw;transform: translateY(-50%);
	}
	.lesson-tit{
		font-size: 2.4rem;line-height: 1.2;
		.tit-l{
			font-size: 3rem;
		}
		.tit-jp{
			position: relative;z-index: 2;
		}
		.tit-en{
			position: absolute;z-index: 1;left:129px;top:-13px;
		}
	}
	.lesson-subtit{
		font-weight: 500;font-size: 2.1rem;line-height: 1.2;margin-top: 15px;
	}
	.lesson-text{
		margin-top: 10px;font-size: 1.3rem;
	}
	.lesson-feature{
		margin-top: 15px;
	}
	.lesson-feature-label{
		font-weight: 500;margin-top: 5px;font-size: 1.5rem;
	}
	.lesson-feature-tit{
		font-weight: 500;font-size: 1.5rem;margin-top: 5px;
	}
	.lesson-feature-text{
		font-size: 1.3rem;text-align: justify;
		padding-left: 17px;
	}
}

.id-lecturer{
	height: 100vh;position: relative;
	.lecturer-box{
		max-width: 512px;width: 40vw;
		position: absolute;z-index: 3;top:50vh;left:60vw;transform: translateY(-50%);
	}
	.lecturer-tit{
		font-size: 2.2rem;line-height: 1.2;
		.tit-jp{
			position: relative;z-index: 2;
		}
		.tit-en{
			position: absolute;z-index: 1;left:90px;top:-8px;
		}
	}
	.lecturer-text{
		max-width: 374px;width: 100%;margin-top: 10px;
		font-size: 1.3rem;text-align: justify;
		a{
			color: $FONT_COLOR;
		}
	}
	.lecturer-book-list{
		width: 100%;margin-top: 10px;margin-left: -4px;
		display: flex;flex-wrap: wrap;
		li{margin-left: 4px;margin-top: 5px;}
	}
}

.id-course{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: calc(((100vh - 43px) / 4) * 3);
		background: rgba(#fff,0.92);
		position: absolute;left:0;bottom:0;z-index: 2;
	}
	.course-box{
		width: 900px;
		display: flex;justify-content: space-between;
		position: absolute;z-index: 3;left:50%;bottom:calc((((100vh - 43px) / 4) * 3) / 2);
		transform: translate(-50%,50%);
	}
	.course-left{
		width: 440px;
	}
	.course-right{
		width: 410px;
	}
	.course-right{
		padding-top: 38px;
	}
	.course-tit{
		font-size: 2.2rem;line-height: 1.2;
		.tit-jp{
			position: relative;z-index: 2;
		}
		.tit-en{
			position: absolute;z-index: 1;left:125px;top:-10px;
		}
	}
	.course-subtit{
		font-weight: 700;font-size: 1.3rem;margin-top: 12px;
	}
	.course-text{
		text-align: justify;font-size: 1.3rem;padding-left: 14px;
	}
	.course-notice{
		position: relative;padding-left: 1em;font-size: 1.3rem;
		.mark{
			position: absolute;left:0;top:0;
		}
	}
}

.id-schedule{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: calc(((100vh - 43px) / 4) * 3 + 43px);
		background: rgba(#fff,0.92);
		position: absolute;left:0;top:0;z-index: 2;
	}
	.schedule-box{
		width: 900px;
		display: flex;justify-content: space-between;
		position: absolute;z-index: 3;left:50%;top:calc((((100vh - 43px) / 4) * 3) / 2 + 43px);
		transform: translate(-50%,-50%);
	}
	
	.schedule-left{
		width: 440px;
	}
	.schedule-right{
		width: 410px;
	}
	.schedule-tit{
		font-size: 2.2rem;line-height: 1.2;
	}
	.schedule-tb{
		margin-top: 12px;font-size: 1.3rem;
		display: flex;
		dt{font-weight: 700;width: 40px;}
		dd{width: calc(100% - 40px);padding-left: 18px;text-align: justify;
			strong{margin-left: -18px;}
		}
	}
	.schedule-notice{
		position: relative;padding-left: 1em;font-size: 1.3rem;
		.mark{
			position: absolute;left:0;top:0;
		}
	}
	.schedule-text{
		margin-top: 10px;letter-spacing: 0;font-size: 1.3rem;text-align: justify;
	}
	.cancel-text{
		margin-top: 12px;font-size: 1.3rem;text-align: justify;
	}
}

.id-gallery1{
	height: 100vh;position: relative;
	.gallery-box1{
		width: 60vw;height: calc((100vh - 43px) / 2);background: rgba($RED,0.85);
		position: absolute;left:20vw;top:calc(((100vh - 43px) / 4) + 43px);z-index: 2;
	}
	.gallery-logo{
		width: 359px;height: 77px;
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
		img{
			position: absolute;z-index: 2;left:0;top:0;
		}
		&:before{
			content:"";width: 441px;height: 159px;
			background: url(../images/logo_gallery_shadow.png) center center no-repeat;background-size:cover;
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);opacity: 0;z-index: 1;
			transition:all 0.3s linear;
		}
		&.is-shadow{
			position: fixed;left:50vw;top:calc(50vh + 21px);
			&:before{
				opacity: 1;
			}
		}
		&.is-out{
			position: absolute;left:50%;top:calc(100vh + 50%);
		}
	}
}

.id-gallery2{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100vw;height: 100%;
		border: calc(20vw + 1px) solid rgba($RED,0.85);
		border-top-width: calc((100vh - 43px) / 4 + 44px);border-bottom-width: calc((100vh - 43px) / 4 + 1px);
		position: absolute;top:0;left:0;z-index: 1;
	}
}

.id-access{
	height: 100vh;position: relative;
	.access-box{
		width: 770px;height: calc(100vh - 43px);margin: 0 auto;background: rgba(255,255,255,0.6);
		position: relative;z-index: 3;top:43px;
	}
	.access-inner{
		width: 100%;background: rgba(255,255,255,0.8);padding: 35px;
		position: absolute;left:0;top:50%;transform: translateY(-50%);
		font-size: 1.3rem;
		display: flex;justify-content: space-between;
	}
	.access-left{
		width: 355px;
	}
	.access-right{
		width: 350px;
	}
	.access-tit{
		
	}
	.access-link{
		margin-top:15px;letter-spacing: 0.05em;font-size: 1.2rem;
	}
	.access-text{
		margin-top: 15px;text-align: justify;
	}
	.access-notice{
		position: relative;padding-left: 1em;font-size: 1.3rem;margin-top: 10px;
		.mark{
			position: absolute;left:0;top:0;
		}
	}
	.access-tb{
		width: 100%;font-size: 1.2rem;letter-spacing: 0.05em;
		th,td{padding-top: 5px;padding-bottom: 5px;}
		tbody th{width: 64px;text-align: right;}
		tbody td{padding-left: 20px;}
	}
	
	.access-map{
		width: 100%;text-align: center;position: relative;margin-top: 20px;
		img{margin: 0 auto;}
	}
	.btn-map{
		display: block;width: 124px;background: #BFBFBF;color: #404040;font-size: 1.1rem;line-height: 1;letter-spacing: 0;text-align: left;
		padding: 4px 0 4px 6px;border-radius: 3px;
		position: absolute;right:30px;bottom:3px;
		transition:opacity 0.3s linear;
		img{
			position: absolute;right:3px;top:calc(50% - 6px)
		}
		&:hover{
			opacity: 0.7;text-decoration: none;
		}
	}
	.access-address{
		padding-left: 50px;margin-top: 15px;
	}
	
	.access-en{
		position: absolute;right:5vw;bottom:10vh;z-index: 	4;
	}
	.access-cake-line{
		width: 100vw;height: calc(100vh - 43px);
		position: absolute;bottom:0;left:0;z-index: 2;
		background: url(../images/access_cake_line.png) center center no-repeat;background-size:cover;
	}
	
}




.bg1{
	width: 100vw;height: calc(100vh - 43px - 38px);
	position: fixed;top:43px;left:0;z-index: 1;
	img{
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
		width: 100%;height: 100%;
		object-fit: cover;
	}
	&.lesson{
		position: absolute;top:calc(100vh + 43px);
	}
}
.bg2{
	width: 100vw;height: calc(100vh - 43px - 38px);
	position: absolute;top:43px;left:0;z-index: 1;
	img{
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
		width: 100%;height: 100%;
		object-fit: cover;
	}
}
.bg3{
	width: 100vw;height: calc(100vh - 43px - 38px);
	position: absolute;top:43px;left:0;z-index: 1;
	img{
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
		width: 100%;height: 100%;
		object-fit: cover;
	}
}
.bg4{
	display: grid;grid-template-rows: 1fr 1fr 1fr 1fr;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: 100vw;height: calc(100vh - 43px);
	position: absolute;left:0;top:43px;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
	&.schedule{
		position: fixed;
	}
	&.gallery{
		position: absolute;top:calc(100vh + 43px);
	}
}
.bg5{
	display: grid;grid-template-rows: 1fr 1fr 1fr 1fr;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: 100vw;height: calc(100vh - 43px);
	position: absolute;left:0;top:43px;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
	&.gallery2{
		position: fixed;
	}
	&.access{
		position: absolute;top:calc(100vh + 43px);
	}
}
.bg6{
	display: grid;grid-template-rows: 1fr 1fr;grid-template-columns: 1fr 1fr;
	width: 100vw;height: calc(100vh - 43px);
	position: absolute;left:0;top:43px;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
}
.cake-line{
	height: 100vh;width:100%;
	position: fixed;top:0;left:0;z-index: 100;
	filter: drop-shadow(0px 0px 0 #EF8661);
	opacity: 0;visibility: hidden;
	transition: all 0.5s linear;
	svg{
		height: 100%;width: auto;position: absolute;
		left: 50%;top:0;transform: translateX(-50%);
	}
	path{
		fill:#EF8661;
	}
	&.is-show{
		opacity: 1;visibility: visible;
	}
}




