//module
a{text-decoration: none;
	&:hover{text-decoration: underline;
		img{opacity:0.7; transition: opacity 0.3s $ease7;}
	}
}
// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;}
.fl-right {	float: right;}
.cl{clear:both;}

//フォントサイズ
.l{font-size:1.8rem;display: inline-block;}
.s{font-size:1.4rem;display: inline-block;}
.xs{font-size:1.2rem;display: inline-block;}

//フォントカラー
.red{	color: $RED;}


.tbdisp{display: none;}
.spdisp{display: none;}




//表組み
table{
	width: 100%;font-size: $FONT_SIZE;line-height: 1.5;
	th,td{vertical-align: top;}
	tbody th{font-weight: bold;}
}


